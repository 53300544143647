import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { useTreatment } from "@yardzen-inc/react-split";
import { FC, useEffect, useState } from "react";
import { CenterFullScreen } from "../../components";
import { useDesignProfileCtx } from "../../components/designProfile/DesignProfileCtx";
import { getLinkToPackagePage } from "../../components/designProfile/util/getLinkToPackagePage";
import { Page } from "../../types/DesignProfile";
import { useGoogleAnalyticsPageview } from "../../util";
import { SegmentFlows, useSegment } from "../../util/Segment";
import { NO_RECOMMEND_ACREAGE_LOT_SIZE } from "../../util/Split/splitTreatments";
import { ACREAGE_LOT, LARGE_LOT } from "../../util/constants/lotSizeTypes";
import { PackageTypes } from "../../util/constants/packageTypes";
import { identifyRecommendedPackage } from "./util/identifyRecommendedPackage";

type RecommendedPackagePageProps = {
  page: Page;
  onNext: () => void;
  onPrevious: () => void;
};

export const RecommendedPackagePage: FC<RecommendedPackagePageProps> = ({
  page,
}) => {
  const segment = useSegment();

  const {
    designProfile,
    isLoading: isDesignProfileLoading,
    isFetching: isDesignProfileFetching,
    isUpdating: isDesignProfileUpdating,
    updateDesignProfile,
  } = useDesignProfileCtx();
  const responses = designProfile?.questions;
  const [isPackageLoading, setIsPackageLoading] = useState<boolean>(true);

  const noRecommendAcreageLotSize = useTreatment(NO_RECOMMEND_ACREAGE_LOT_SIZE);

  const identifiedPackage = identifyRecommendedPackage({
    responses: responses ?? [],
  });

  if (noRecommendAcreageLotSize && identifiedPackage?.lotSize === ACREAGE_LOT) {
    identifiedPackage.lotSize = LARGE_LOT;
  }

  const loading =
    isDesignProfileLoading ||
    isPackageLoading ||
    !identifiedPackage ||
    !identifiedPackage.pkg ||
    isDesignProfileFetching ||
    isDesignProfileUpdating;

  useEffect(() => {
    segment.trackFlowCompleted({
      flow_name: SegmentFlows.DESIGN_PROFILE,
    });
  }, [segment]);

  useEffect(() => {
    if (identifiedPackage?.pkg) {
      updateRecommendedPackageOnDesignProfile(identifiedPackage.pkg);
    }

    async function updateRecommendedPackageOnDesignProfile(
      recommendedPackage: PackageTypes
    ) {
      try {
        await updateDesignProfile({
          recommendedPackage: recommendedPackage,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsPackageLoading(false);
      }
    }
    // The design profile update causes infinite rerenders if we include the design profile object in the dependency array. We are reworking the design profile soon anyways, so we will live with disabling the dependency warning for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDesignProfile]);

  useEffect(() => {
    if (!loading) {
      window.location.href = getLinkToPackagePage(
        identifiedPackage.pkg,
        identifiedPackage.lotSize,
        designProfile?.contactInformation?.email,
        true
      );
    }
  }, [loading, identifiedPackage, designProfile]);

  useGoogleAnalyticsPageview(`/design-profile/${page.path}`);

  // For now we have TT turned off
  // useTikTokTrackRegistrationComplete();

  // this is a fallback in case the design profile request fails
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (loading && identifiedPackage && identifiedPackage.pkg) {
        window.location.href = getLinkToPackagePage(
          identifiedPackage.pkg,
          identifiedPackage.lotSize
        );
      } else {
        // if identified package fails (just send to packages)
        window.location.href = `${process.env.REACT_APP_WORDPRESS_BASE_URL}/packages`;
      }
    }, 6000);
    return () => window.clearTimeout(timeoutID);
  }, [loading, identifiedPackage]);

  return (
    <CenterFullScreen>
      <GenericLoadingSpinner />
    </CenterFullScreen>
  );
};
